import React from "react"
import ReactDOM from "react-dom/client"

import { BrowserRouter, Routes, Route } from "react-router-dom"

import Horizontal from "./Horizontal"
import Vertical from "./Vertical"
import VerticalV2 from "./v2/Vertical"
import HorizontalV2 from "./v2/Horizontal"

const root = ReactDOM.createRoot(document.getElementById("content"));
root.render(
	<BrowserRouter>
		<Routes>
			<Route path="/h" element={<Horizontal />} />
			<Route path="/v" element={<Vertical />} />
			<Route path="/v2/v" element={<VerticalV2 />} />
			<Route path="/v2/h" element={<HorizontalV2 />} />
		</Routes>
	</BrowserRouter>
);