import React from "react"
import { Row, Col, Form, Button } from "react-bootstrap"
import "./style.css"

class InputRow extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
    }

    onChange() {
        var meas = this.props.control.current.value*this.props.mult.current.value
        this.props.update(meas)
    }

    render() {
        return(
            <Form.Group as={Row} className="no-gutters">
                <Col>
                    <Form.Control ref={this.props.control} onChange={this.onChange} placeholder={`${this.props.label}:`} type="float" className="concrete-calc-control concrete-calc-v2"/>
                </Col>
                <Col>
                    <Form.Control ref={this.props.mult} onChange={this.onChange} as="select" className="concrete-calc-control concrete-calc-v2">
                        <option value="1000">m (Metres)</option>
                        <option value="10">cm (Centimetres)</option>
                        <option value="1">mm (Millimetres)</option>
                        <option value="914.4">yd (Yards)</option>
                        <option value="304.8">ft (Feet)</option>
                        <option value="25.4">in (Inches)</option>
                    </Form.Control>
                </Col>
            </Form.Group>
        )
    }
}

export default class Horizontal extends React.Component {
    constructor(props) {
        super(props)
        this.calculate = this.calculate.bind(this)
        this.reset = this.reset.bind(this)
        this.state = {
            metreCubic: 0,
            length: 0,
            width: 0,
            depth: 0
        }

        this.len1 = React.createRef()
        this.len2 = React.createRef()

        this.wid1 = React.createRef()
        this.wid2 = React.createRef()

        this.dep1 = React.createRef()
        this.dep2 = React.createRef()
    }

    calculate(e) {
        e.preventDefault()
        if (!(this.state.length && this.state.width && this.state.depth)) { return }

        var metreCubic = (this.state.length*this.state.width*this.state.depth)/1000000000
        this.setState({
            metreCubic: metreCubic,
        })
    }

    reset(e) {
        e.preventDefault()

        this.len1.current.value = null
        this.len2.current.value = "1000"

        this.wid1.current.value = null
        this.wid2.current.value = "1000"

        this.dep1.current.value = null
        this.dep2.current.value = "1000"

        this.setState({
            metreCubic: 0,
            length: 0,
            width: 0,
            depth: 0
        })
    }

    render() {
        return(
            <div className="w-100">
                <Form onSubmit={this.calculate}>
                    <InputRow label="Length" control={this.len1} mult={this.len2} update={(v) => {this.setState({length: v})}}/>
                    <InputRow label="Width" control={this.wid1} mult={this.wid2} update={(v) => {this.setState({width: v})}}/>
                    <InputRow label="Depth" control={this.dep1} mult={this.dep2} update={(v) => {this.setState({depth: v})}}/>
                    <h3 className="my-3 text-center concrete-calc-out">You will need {this.state.metreCubic}m&sup3;<br/>Call Now For Quick Delivery<br/><a className="concrete-calc-href text-decoration-none" href="tel:02084320979">020 8432 0979</a></h3>
                    <Row className="no-gutters">
                        <Col>
                            <Button type="submit" className="concrete-calc-submit font-weight-normal w-100 text-center">Calculate</Button>
                        </Col>
                        <Col>
                            <Button onClick={this.reset} className="concrete-calc-reset font-weight-normal w-100 text-center">Reset</Button>
                        </Col>
                    </Row>
                </Form>

            </div>
        )
    }
}