import React from "react"
import { Row, Col, Form, Button } from "react-bootstrap"

class InputRow extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.control = React.createRef()
        this.mult = React.createRef()
    }

    onChange() {
        var meas = this.control.current.value*this.mult.current.value
        console.log(meas)
        this.props.update(meas)
    }

    render() {
        return(
            <Form.Group as={Row} className="no-gutters">
                <Col className="my-auto">
                    <Form.Label className="mb-0 font-weight-bold concrete-calc-label">{this.props.label}</Form.Label>
                </Col>
                <Col className="pr-3">
                    <Form.Control ref={this.control} onChange={this.onChange} type="number" className="concrete-calc-control"/>
                </Col>
                <Col xs="auto">
                    <Form.Control ref={this.mult} onChange={this.onChange} as="select" className="concrete-calc-control">
                        <option value="1000">m (Metres)</option>
                        <option value="10">cm (Centimetres)</option>
                        <option value="1">mm (Millimetres)</option>
                        <option value="914.4">yd (Yards)</option>
                        <option value="304.8">ft (Feet)</option>
                        <option value="25.4">in (Inches)</option>
                    </Form.Control>
                </Col>
            </Form.Group>
        )
    }
}

export default class Horizontal extends React.Component {
    constructor(props) {
        super(props)
        this.calculate = this.calculate.bind(this)
        this.state = {
            length: 0,
            width: 0,
            depth: 0,
            out: null
        }
    }

    calculate(e) {
        e.preventDefault()

        var metreCubic = (this.state.length*this.state.width*this.state.depth)/1000000000
        this.setState({
            metreCubic: metreCubic,
            kgs: metreCubic*2400
        })
    }

    render() {
        return(
            <div className="w-100">
                <Form onSubmit={this.calculate}>
                    <InputRow label="Length" update={(v) => {this.setState({length: v})}}/>
                    <hr className="concrete-calc-hr"/>
                    <InputRow label="Width" update={(v) => {this.setState({width: v})}}/>
                    <hr className="concrete-calc-hr"/>
                    <InputRow label="Depth" update={(v) => {this.setState({depth: v})}}/>
                    <hr className="concrete-calc-hr"/>
                    <div className="text-center">
                        <Button type="submit" className="text-center">Calculate</Button>
                        <h4 className="mt-3 concrete-calc-out">{this.state.metreCubic && `${this.state.metreCubic} m3`}{this.state.kgs && `, ${this.state.kgs} kgs`}</h4>
                    </div>
                </Form>

            </div>
        )
    }
}